import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Taxi App Template | Taxi App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/taxi-mobile-app/"
    metaDescription="Introducing CabTap; a mobile taxi app design template for your own design project. Bring your taxi app idea to life in prototype format with Uizard Pro."
    description="
    h2:CabTap: a taxi app design template for mobile devices
    <br/>
    Working on your own vision for a taxi service mobile app? CabTap, one of our brand-new <a:https://uizard.io/templates/mobile-app-templates/>app templates</a> is the perfect way to fast-track your design flow. Packed with all the screen mockups your design will need, you can use the CabTap template as it is or customize it to suit your digital product vision before handing it off to a developer.
    <br/>
    h3:CabTap puts you in the driving seat with limitless collaboration
    <br/>
    Uizard is built for collaboration, meaning if you are working on your <a:https://uizard.io/prototyping/>app UI prototype</a> with a team, adding new team members to your project is quick and easy. Simply sign up for Uizard Pro, start your project and share it with your organization, you can even edit your designs collaboratively in real time!
    <br/>
    h3:Arrive at your design destination faster with rapid prototyping
    <br/>
    Uizard's drag-and-drop editor empowers you to prototype like never before. With no design experience, you can create screens, add UI elements, link pages, and add your own branding. Kickstart your taxi app design project with Uizard's <a:https://uizard.io/ai-design/>AI design</a> features today!
    "
    pages={[
      "Log-in flow with entry screen and user profile details",
      "Taxi order screen with local map mockup and order CTA",
      "Order confirmation screen with driver details and ETA",
      "Order completion screen and contact driver mockup",
    ]}
    projectCode="XX01jla8ewhOAPy9EV57"
    img1={data.image1.childImageSharp}
    img1alt="taxi mobile app cover screen"
    img2={data.image2.childImageSharp}
    img2alt="taxi mobile app homepage"
    img3={data.image3.childImageSharp}
    img3alt="taxi mobile app profile screen"
    img4={data.image4.childImageSharp}
    img4alt="taxi mobile app trip screen"
    img5={data.image5.childImageSharp}
    img5alt="taxi mobile app summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/taxi-mobile-app/tax-mobile-cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/taxi-mobile-app/tax-mobile-homepage.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/taxi-mobile-app/tax-mobile-profile.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/taxi-mobile-app/tax-mobile-trip.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/taxi-mobile-app/tax-mobile-summary.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
